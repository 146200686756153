import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import {useHistory} from 'react-router-dom'
import api from "../utils/axios";

export const LocalContext = createContext({});

export const ContextProvider = ({children}) => {

    const history = useHistory();
    const [user, setUser] = useState({});
    const [info, setInfo] = useState('');
    const token = localStorage.getItem('token')

    const Roles = async () => {
        try {
            const { data } = await api.get('/api/v1/user');
            if (data) {
                setUser(data)
            }
        } catch (error) {
            console.log(error);
            localStorage.removeItem('token');
            // history.push('/login')
        }
    };

    const getPartnerInfo = (value) => {
      setInfo(value)
    }

    useEffect(() => {
      Roles()
    }, []);

    return (
        <LocalContext.Provider
          value={{
            user,
            getPartnerInfo,
            info
          }}
        >
          {children}
        </LocalContext.Provider>
      );
};

export const Context = () => useContext(LocalContext) 