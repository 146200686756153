import { Col, Row } from "antd";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DataFormater } from '../../utils/helper';
import popup from '../../utils/popup';
import ChartReports from './Charts';
import Revenue from './Revenue';
import api from "../../utils/axios";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dataForCharts, setDataForCharts] = useState([]);
  const [revenue, setRevenue] = useState({})
  const getDataForReports = async () => {
    try {
      setLoading(true);
      const res = await api.get('/api/v1/report/revenue/trending');
      if(res) {
        let temp = res?.data?.map((item) => {
          const time = moment(item?.time, "MM-YYYY").format("MM-YYYY");
          const data = {
            time: time,
            value: item?.total,
            count: item?.count
          };
          return data
        });
        setDataForCharts(temp);
        setRevenue(res?.report)
        setLoading(false);
      }
    } catch (error) {
      popup.error("Có lỗi xảy ra !", 4);
      setLoading(false)
    }
  };

  useEffect(() => {
    getDataForReports();
  }, []);

  return (
    <Row gutter={[8,8]} className="p-6">
      <Col span={24}>
        <Revenue data={revenue} loading={loading}/>
      </Col>
      <Col span={24}>
        <ChartReports data={dataForCharts} loading={loading} dataFormat={DataFormater}/>
      </Col>
    </Row>
  )
}

export default Dashboard