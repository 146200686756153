import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import api from '../../utils/axios';
import {CloseOutlined} from '@ant-design/icons';

const {Text} = Typography;
const {TextArea} = Input;
const formData = [
  {
    label: 'Code',
    type: 'string',
    key: 'code',
    message: 'Vui lòng nhập code',
    required: true,
  },
  {
    label: 'Tên gói',
    type: 'string',
    key: 'name',
    message: 'Vui lòng nhập tên gói',
    required: true,
  },
  {
    label: 'Ảnh minh họa',
    type: 'string',
    key: 'cover',
    message: 'Vui lòng nhập link ảnh minh họa',
    required: true,
  },
  {
    label: 'Gọi nội mạng',
    type: 'number',
    key: 'internalCallTotalHour',
    message: 'Vui lòng nhập thời gian gọi nội mạng',
    required: true,
  },
  {
    label: 'Gọi ngoại mạng',
    type: 'number',
    key: 'externalCallTotalHour',
    message: 'Vui lòng nhập thời gian gọi nội mạng',
    required: true,
  },
  {
    label: 'Giá',
    type: 'number',
    key: 'price',
    message: 'Vui lòng nhập giá',
    required: true,
  },
  {
    label: 'Thời hạn',
    type: 'number',
    key: 'durationDay',
    message: 'Vui lòng nhập thời hạn',
    required: true,
  },
  {
    label: 'Giảm giá',
    type: 'number',
    key: 'discountRate',
    message: '',
    required: false,
  },
  {
    label: 'Kho',
    type: 'number',
    key: 'storage',
    message: 'Vui lòng số lượng kho',
    required: true,
  },
  {
    label: 'Order',
    type: 'number',
    key: 'order',
    message: '',
    required: false,
  },
];

export const DataManageModal = ({
  onClose,
  categories = [],
  form,
  open,
  mode,
  openNotification,
  getListData,
  supplier,
}) => {
  const onAdd = async data => {
    try {
      await api.post('/api/v1/mobileData/create', data).then(res => {
        openNotification(
          'success',
          `Bạn đã tạo gói mới ${data.name} thành công`,
        );
      });
    } catch (e) {
      openNotification(
        'failed',
        `Bạn đã tạo gói mới ${data.name} thất bại, mã lỗi: ${e.error.message}`,
      );
      console.error(e);
    } finally {
      onClose();
      getListData();
    }
  };

  const onUpdate = async data => {
    try {
      await api.put('/api/v1/mobileData/update', data).then(res => {
        openNotification('success', `Bạn đã sửa gói ${data.name} thành công`);
      });
    } catch (e) {
      openNotification(
        'failed',
        `Bạn đã sửa gói ${data.name} thất bại, mã lỗi ${e.error.message}`,
      );
      console.error(e);
    }
    onClose();
    getListData();
  };

  let modalOKText = 'Thêm';
  let modalTitle = 'Thêm gói';

  if (mode === 'update') {
    modalOKText = 'Lưu';
    modalTitle = 'Cập nhật';
  }

  const onFinish = values => {
    mode === 'add' ? onAdd(values) : onUpdate(values);
  };

  const renderMoreCard = (field, name, remove) => {
    return (
      <Card
        size="small"
        title={`More ${field.name + 1}`}
        key={field.key}
        extra={
          <CloseOutlined
            onClick={() => {
              remove(field.name);
            }}
          />
        }>
        <Form.Item label="Name" name={[field.name, 'name']}>
          <Input style={{borderRadius: 12}} placeholder={'Name'} />
        </Form.Item>

        {name >= 2 ? (
          <Form.Item>
            <Form.List name={[field.name, 'items']}>
              {(countryFields, {add: countryAdd, remove: countryRemove}) => {
                return (
                  <div>
                    {countryFields.map(country => {
                      return (
                        <>
                          <Text>Items</Text>
                          <Form.Item name={[country.name, 'country']}>
                            <Input
                              style={{borderRadius: 12}}
                              placeholder={'Country'}
                            />
                          </Form.Item>
                          <Form.List name={[country.name, 'items']}>
                            {(
                              countryItemFields,
                              {add: countryItemAdd, remove: countryItemRemove},
                            ) => {
                              return (
                                <div>
                                  {countryItemFields.map(countryItem => {
                                    return (
                                      <Row gutter={[12, 0]}>
                                        <Col span={12}>
                                          <Form.Item
                                            name={[
                                              countryItem.name,
                                              'partner',
                                            ]}>
                                            <Input
                                              style={{borderRadius: 12}}
                                              placeholder="Partner"
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name={[
                                              countryItem.name,
                                              'handSet',
                                            ]}>
                                            <Input
                                              style={{borderRadius: 12}}
                                              placeholder={'Handset'}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                  <Button
                                    type="primary"
                                    style={{marginBottom: 12}}
                                    onClick={() => countryItemAdd()}
                                    block>
                                    + Add partner/handset
                                  </Button>
                                </div>
                              );
                            }}
                          </Form.List>
                        </>
                      );
                    })}
                    <Button
                      type="primary"
                      style={{backgroundColor: '#22BB33'}}
                      onClick={() => countryAdd()}
                      block>
                      + Add Country
                    </Button>
                  </div>
                );
              }}
            </Form.List>
          </Form.Item>
        ) : (
          <Form.Item label="Items">
            <Form.List name={[field.name, 'items']}>
              {(subFields, subOpt) => (
                <div
                  style={{
                    flexDirection: 'column',
                  }}>
                  {subFields.map(subField => (
                    <Row>
                      <Form.Item
                        style={{flex: 1, marginRight: 12}}
                        name={[subField.name]}>
                        <Input style={{borderRadius: 12}} placeholder="Items" />
                      </Form.Item>

                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name);
                        }}
                      />
                    </Row>
                  ))}
                  <Button
                    type="primary"
                    style={{backgroundColor: '#22BB33'}}
                    onClick={() => subOpt.add()}
                    block>
                    + Add Item
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        )}
      </Card>
    );
  };

  return (
    <Modal
      width={1200}
      okText={modalOKText}
      title={modalTitle}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        onClose();
      }}
      open={open}>
      <Form
        onFinish={onFinish}
        autoComplete={'off'}
        form={form}
        layout={'vertical'}>
        <Row gutter={[12, 0]}>
          {formData.map(field => {
            const {label, key, required, type, message} = field;
            return (
              <Col span={type === 'number' ? 6 : 8}>
                <Form.Item
                  label={label}
                  name={key}
                  rules={[{required, type, message}]}>
                  {type === 'number' ? (
                    <InputNumber style={{width: '100%'}} />
                  ) : (
                    <Input style={{borderRadius: 12}} />
                  )}
                </Form.Item>
              </Col>
            );
          })}
          <Col>
            <Form.Item
              rules={[{required: true, message: 'Vui lòng nhập loại gói'}]}
              name={'status'}
              label="Trạng thái">
              <Select
                options={[
                  {
                    label: 'Inactive',
                    value: 1,
                  },
                  {
                    label: 'Active',
                    value: 0,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={6}>
            <Form.Item
              rules={[{required: true, message: 'Vui lòng nhập loại gói'}]}
              name={'categories'}
              label="Loại gói">
              <Select mode="multiple" options={categories} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              rules={[{required: false}]}
              name={'suppliers'}
              label="Nhà cung cấp">
              <Select mode="multiple" options={supplier} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'simType'} label="Loại sim">
              <Select
                options={[
                  {label: 'normalSim', value: 0},
                  {label: 'BHM', value: 1},
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'type'} label="Type">
              <Select
                options={[
                  {label: 'normal', value: 0},
                  {label: 'outOfStock', value: 1},
                  {label: 'onlyData', value: 2},
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          rules={[{required: true, message: 'Vui lòng nhập mô tả'}]}
          name={'description'}
          label="Mô tả">
          <TextArea />
        </Form.Item>
        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.List name="shortDescription">
              {(fields, {add, remove}) => (
                <div>
                  <Row style={{marginBottom: 12}}>
                    <Text style={{marginRight: 12}}>Mô tả ngắn</Text>
                    <Button
                      type="primary"
                      shape="round"
                      size="small"
                      className="gx-mb-1 gx-width-90"
                      onClick={() => add()}>
                      Thêm mô tả
                    </Button>
                  </Row>

                  {fields.map(({key, name, ...restField}) => (
                    <Row key={key} style={{marginBottom: 12, height: 60}}>
                      <Form.Item
                        {...restField}
                        name={name}
                        style={{flex: 1, marginRight: 12}}>
                        <TextArea />
                      </Form.Item>
                      <CloseOutlined
                        style={{marginTop: 12}}
                        onClick={() => remove(name)}
                      />
                    </Row>
                  ))}
                </div>
              )}
            </Form.List>
          </Col>
          <Col span={12}>
            <Form.List name="more">
              {(fields, {add, remove}) => (
                <div
                  style={{
                    display: 'flex',
                    rowGap: 16,
                    flexDirection: 'column',
                  }}>
                  {fields.map((field, name) => {
                    return renderMoreCard(field, name, remove);
                  })}
                  <Button type="default" onClick={() => add()} block>
                    + Add More
                  </Button>
                </div>
              )}
            </Form.List>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Typography>
              <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
            </Typography>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
