import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  notification, AutoComplete,
} from 'antd';
import B2BDataModal from '../components/Modal/B2BDataModal';
import { DataManageModal } from '../components/Modal/DataManagement';
import DataViewModal from '../components/Modal/DataViewModal';
import api from '../utils/axios';
import {formatNumber} from '../utils/Utils';

const {Text} = Typography;

const initialRecord = {
  id: '',
  name: '',
  code: '',
  cover: ' ',
  description: '',
  shortDescription: [],
  durationDay: undefined,
  price: undefined,
  storage: undefined,
  discountRate: 0,
  internalCallTotalHour: undefined,
  externalCallTotalHour: undefined,
  categories: [],
  suppliers: [],
  status: 1,
  order: 0,
  type: 0,
  simType: 0,
};

const SIM_TYPE = ['gói Topup', 'Bộ Hòa Mạng'];
const TYPE = ['normal', 'outOfStock', 'dataOnly'];

const DataManagement = () => {
  const [data, setData] = useState([]);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [b2bModalVisible, setB2bModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [modalMode, setModalMode] = useState('add');
  const [modalOpen, setModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({});
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [options, setOptions ] = useState([])

  useEffect(() => {
    getCategories();
    getSuppliers();
  }, []);

  useEffect(() => {
    getListData(currentPage);
  }, [currentPage, filters]);

  const openNotification = (type = 'success', description) => {
    type === 'success'
      ? notification.success({
          message: 'Thành công',
          placement: 'top',
          description,
        })
      : notification.error({
          message: 'Thất bại',
          placement: 'top',
          description,
        });
  };

  const getListData = async (page = 1) => {
    try {
      await api
        .get('/api/v1/mobileData/listMobileDataPaging', {
          params: {...filters, limit: 8, page: page - 1},
        })
          .then(data => {
          setData(data?.data);
          setTotal(data?.paging?.total || 0);
            const formattedOptions = data?.data?.map(item => ({
              value: item.name
            }))
            setOptions(formattedOptions)
        })
    } catch (e) {
      console.error('Error get list data', e);
    }
  };

  const getCategories = async () => {
    try {
      await api
        .get('/api/v1/mobile/data/categories')
        .then(data => setCategories(data?.data));
    } catch (e) {
      console.error('Error get list categories', e);
    }
  };

  const getSuppliers = async () => {
    try {
      await api.get('/api/v1/supplier').then(data => setSuppliers(data?.data));
    } catch (e) {
      console.error('Error get list suppliers', e);
    }
  };

  const showModal = (mode, record = initialRecord) => {
    const formattedRecord = {
      ...record,
      price: Number(record.price),
      discountRate: Number(record.discountRate),
    };

    setModalMode(mode);
    setSelectedRecord(record);
    setModalOpen(true);
    form.setFieldsValue(formattedRecord);
  };

  const showViewModal = record => {
    setSelectedRecord(record);
    setViewModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const onDelete = async () => {
    try {
      await api
        .delete('/api/v1/mobileData/delete', {
          data: {data: [{code: selectedRecord?.code}]},
        })
        .then(res => {
          if (res.passedDelete?.length !== 0) {
            openNotification(
              'success',
              `Bạn đã xóa gói ${selectedRecord?.name} thành công`,
            );
          } else {
            openNotification(
              'failed',
              `Bạn đã xóa gói ${selectedRecord?.name} thất bại`,
            );
          }
        });
    } catch (error) {
      openNotification(
        'failed',
        `Bạn đã xóa gói ${selectedRecord?.name} thất bại, mã lỗi: ${error.error.message}`,
      );
      console.error('Error deleting data:', error);
    } finally {
      setConfirmVisible(false);
      getListData();
    }
  };

  const formattedCategories = categories.map(category => {
    return {
      value: category?.code || '',
      label: category?.name || '',
    };
  });

  const formattedSupplier = suppliers
    .filter(supplier => supplier?.type === 0)
    .map(supplier => {
      return {
        value: supplier?.id || '',
        label: supplier?.name || '',
      };
    });

  const showModalDelete = record => {
    setSelectedRecord(record);
    setConfirmVisible(true);
  };

  const columns = [
    {
      title: 'STT',
      width: 60,
      render: (item, record, index) => (
        <span style={{fontWeight: 'bold'}}>
          {index + 1 + (currentPage - 1) * 8}
        </span>
      ),
      align: 'center',
    },
    {
      title: 'Tên gói',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 150,
    },
    {
      title: 'Thời hạn',
      dataIndex: 'durationDay',
      key: 'durationDay',
      width: 80,
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 100,
      render: (text, record) => {
        return <Text ellipsis={true}>{formatNumber(text)}</Text>;
      },
    },
    {
      title: 'Kho',
      dataIndex: 'storage',
      key: 'storage',
      width: 100,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text, record) => {
        return <Text ellipsis={true}>{!text ? 'Active' : 'Inactive'}</Text>;
      },
    },
    {
      title: 'Loại gói',
      dataIndex: 'simType',
      key: 'simType',
      width: 100,
      render: (text, record) => {
        return <Text ellipsis={true}>{SIM_TYPE[text]}</Text>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (text, record) => {
        return <Text ellipsis={true}>{TYPE[text]}</Text>;
      },
    },
    {
      title: 'Gói',
      dataIndex: 'categories',
      key: 'categories',
      width: 200,
      render: (text, record = []) => {
        return (
          <div>
            {record?.categories?.map?.(item => {
              const foundItem = formattedCategories.find(
                i => i?.value === item,
              );
              return <Tag>{foundItem?.label}</Tag>;
            })}
          </div>
        );
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: 200,
    },
    {
      title: 'Action',
      key: 'action',
      width: 160,
      fixed: 'right',
      render: (text, record) => (
        <span>
          <Button
            type="primary"
            ghost
            shape="round"
            size="small"
            className="gx-mb-1 gx-width-90"
            onClick={() => showViewModal(record)}>
            Xem
          </Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            shape="round"
            size="small"
            className="gx-mb-1 gx-width-90 mr-2"
            onClick={() => showModal('update', record)}>
            Sửa
          </Button>
          <Button
            type="primary"
            shape="round"
            size="small"
            className="gx-mb-1 gx-width-90  bg-red-600 hover:bg-red-400"
            onClick={() => showModalDelete(record)}>
            Xóa
          </Button>
        </span>
      ),
    },
  ];

  const onFilterSubmit = values => {
    const newValues = {
      name: values?.name?.trim(),
      code: values?.code?.trim(),
      status: values?.status
    }
    setFilters(newValues);
  };

  const getPanelValue = (searchText) => {
    const filteredOptions =  options?.filter(option => option.value.toLowerCase().includes(searchText.toLowerCase()));

    if (filteredOptions?.length === 0) {
      return data?.map(item => {
        return {
          value: item.name
        }
      })
    }

    return filteredOptions
  }

  return (
    <div
      style={{
        flex: 1,
        height: '100vh',
        marginBottom: 100,
      }}>
      <Card title={'Bộ lọc'} style={{margin: 12}}>
        <Form onFinish={onFilterSubmit} layout={'vertical'} form={filterForm}>
          <Row align={'middle'} gutter={[12, 0]}>
            <Col span={8}>
              <Form.Item name={'name'}>
                <AutoComplete
                    options={options}
                    onSearch={(text) => setOptions(getPanelValue(text.trim()))}
                    placeholder="Tìm theo tên"
                    style={{borderRadius: 12, height: 40}}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item  name={'code'}>
                <Input
                  style={{borderRadius: 12}}
                  placeholder={'Tìm theo code'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'status'}>
                <Select
                  size="large"
                  placeholder={'Tìm theo trạng thái'}
                  options={[
                    {
                      label: 'Inactive',
                      value: 1,
                    },
                    {
                      label: 'Active',
                      value: 0,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              span={24}>
              <Row>
                <Form.Item>
                  <Button
                    style={{marginRight: 8}}
                    onClick={() => {
                      filterForm.submit();
                    }}
                    type={'primary'}>
                    Tìm
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => {
                      filterForm.resetFields();
                      setFilters({});
                    }}
                    danger
                    type={'primary'}>
                    Xóa bộ lọc
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        style={{margin: 12}}
        title="Quản lý gói data"
        extra={
          <div>
            <Button
              style={{borderRadius: 8, marginRight: 12}}
              type="primary"
              onClick={() => setB2bModalVisible(true)}>
              Thêm gói data từ B2B
            </Button>
            <Button
              style={{borderRadius: 8}}
              type="primary"
              onClick={() => showModal('add')}>
              Thêm mới gói data
            </Button>
          </div>
        }>
        <Table
          pagination={false}
          dataSource={data}
          columns={columns}
          scroll={{x: 2000, y: 900}}
        />
        <Pagination
          style={{marginTop: 12}}
          current={currentPage}
          total={total}
          pageSize={8}
          onChange={setCurrentPage}
        />

        <DataManageModal
          categories={formattedCategories}
          supplier={formattedSupplier}
          onClose={onClose}
          getListData={getListData}
          form={form}
          open={modalOpen}
          mode={modalMode}
          openNotification={openNotification}
        />
        <DataViewModal
          record={selectedRecord}
          setOpen={setViewModalOpen}
          open={viewModalOpen}
        />
        <Modal
          title={'Xác nhận'}
          onCancel={() => setConfirmVisible(false)}
          onOk={onDelete}
          open={confirmVisible}>
          <p>{`Bạn có chắc chắn muốn xóa gói tên ${selectedRecord?.name} - code ${selectedRecord?.code}`}</p>
        </Modal>
        <B2BDataModal
          onCancel={() => {
            setB2bModalVisible(false);
          }}
          getListData={getListData}
          visible={b2bModalVisible}
          openNotification={openNotification}
        />
      </Card>
    </div>
  );
};

export default DataManagement;
