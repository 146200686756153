import { BarcodeOutlined, CodeOutlined, LoadingOutlined, MailOutlined, PhoneOutlined, SnippetsOutlined, UserOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, Input, Modal, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';

const ActionView = (props) => {
  const { getSimList, simList, setModal, modal, actionLoading, actionForm, handleActions, info, setInfo } = props;

  const filterSim = (value) => {
    let sim = simList?.filter((item) => item?.sim === value);
    if(sim) {
      actionForm.setFieldValue('package', sim[0]?.dataPackage)
    }
  }

  return (
    <Modal title={!isEmpty(info) ? 'Chi tiết thông tin' : 'Thêm mới thông tin'} open={modal} centered destroyOnClose footer={null} closable={false}>
      <Spin
        spinning={actionLoading}
        indicator={
          <LoadingOutlined className="text-blue-600 font-semibold" />
        }
        tip={<span className="font-semibold">{isEmpty(info) ? 'Đang thêm mới...' : 'Đang cập nhật...'}</span>}>
        <Form form={actionForm} onFinish={(values) => handleActions(values, !isEmpty(info) ? 'edit' : 'create', info)}>
          <Form.Item name={'number'} label='Số Sim' initialValue={!isEmpty(info) ? info?.number : null} rules={[
            {
              required: true,
              type: 'string',
              message: 'Số Sim không được để trống !'
            }
          ]}>
            <AutoComplete options={simList.map(item => ({ value: item.sim }))} onSelect={(value) => filterSim(value)} onSearch={(value) => getSimList(value)} children={<Input placeholder='Nhập số sim...' suffix={<BarcodeOutlined className='pl-1' />} />} />
          </Form.Item>
          {isEmpty(info) && (
            <Form.Item name={'package'} label={'Loại gói'} rules={[
              {
                required: true,
                type: 'string'
              },
            ]}>
              <Input placeholder='Nhập số sim để hiện thông tin gói' className='pointer-events-none' suffix={<SnippetsOutlined className='pl-1' />} />
            </Form.Item>
          )}
          {!isEmpty(info) && (
            <Form.Item name={'orderCode'} label='Mã đơn hàng' initialValue={!isEmpty(info) ? info?.orderCode : null} rules={[
              {
                required: false,
                type: 'string',
                message: 'Mã đơn hàng không được để trống !'
              }
            ]}>
              <Input suffix={<CodeOutlined className="pl-1" />} className='pointer-events-none' />
            </Form.Item>
          )}

          <Form.Item name={'customerName'} label='Tên khách hàng' initialValue={!isEmpty(info) ? info?.customerName : null} rules={[
            {
              required: true,
              type: 'string',
              message: 'Tên khách hàng không để trống !'
            }
          ]}>
            <Input placeholder='Vui lòng nhập tên khách hàng' suffix={<UserOutlined className="pl-1" />} />
          </Form.Item>
          <Form.Item name={'customerPhone'} label='Điện thoại liên hệ' initialValue={!isEmpty(info) ? info?.customerPhone : null} rules={[
            {
              required: true,
              type: 'string',
              max: 10,
              message: 'Điện thoại liên hệ không được trống hoặc quá 10 số !'
            }
          ]}>
            <Input placeholder='Vui lòng nhập số điện thoại' maxLength={10} suffix={<PhoneOutlined className="pl-1" />} />
          </Form.Item>
          <Form.Item name={'address'} label='Địa chỉ khách hàng' initialValue={!isEmpty(info) ? info?.address : null} rules={[
            {
              required: true,
              type: 'string',
              message: 'Vui lòng nhập địa chỉ khách hàng !'
            }
          ]}>
            <Input.TextArea placeholder='Vui lòng nhập địa chỉ' minLength={1} className='overflow-hidden' />
          </Form.Item>
          <Form.Item name={'email'} label='Email khách hàng' initialValue={!isEmpty(info) ? info?.email : null} rules={[
            {
              required: true,
              type: 'email',
              message: 'Vui lòng nhập email khách hàng !'
            }
          ]}>
            <Input placeholder='Vui lòng nhập email (example@gmail.com)' suffix={<MailOutlined className='pl-1' />} />
          </Form.Item>
          {!isEmpty(info) && (<p className='text-blue-700'><i className='text-red-700 underline'>Lưu ý:</i> Thông tin có dấu <b className='text-red-700'>*</b> là thông tin có thể chỉnh sửa</p>)}
          <br />
          <Form.Item className='flex justify-center items-center'>
            <Button type='primary' htmlType='submit'>{isEmpty(info) ? 'Thêm mới' : 'Cập nhật'}</Button>
            <Button onClick={() => {
              actionForm.resetFields();
              setInfo({});
              setModal(false);
            }} className='ml-2'>Quay lại</Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>

  )
}

export default ActionView