import React from 'react';
import { Button, Card, Input, Form, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { UnlockOutlined, SafetyOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';
import api from '../utils/axios';
import popup from '../utils/popup';

function ChangePassword() {
  const history = useHistory();
  const [info, setInfo] = React.useState({});
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async () => {
    try {
      if (!info.oldPassword && !info.password && !info.confirmPassword) {
        return setError({ oldPassword: true, password: true, confirmPassword: true });
      }
      if (!info.oldPassword) return setError({ ...error, oldPassword: true });
      if (!info.password) return setError({ ...error, password: true });
      if (!info.confirmPassword) return setError({ ...error, confirmPassword: 'Vui lòng xác nhận mật khẩu' });

      if (info.password !== info.confirmPassword) {
        return setError({ ...error, confirmPassword: 'Xác nhận mật khẩu không giống nhau' });
      }
      setLoading(true);

      const body = {
        oldPassword: info.oldPassword,
        newPassword: info.confirmPassword,
      };

      const { data } = await api.put('/api/v1/user/change-password', body);
      if (data) {
        popup.success('Bạn đã đổi mật khẩu thành công');
        localStorage.removeItem('token');
        history.push('/login');
      }

      setLoading(true);
    } catch (err) {
      setLoading(false);
      console.log(err);
      popup.error(err && err.error?.message);
    }
  };

  const handleOnChange = (e) => {
    setError({});
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <Spin spinning={loading}>
        <Card title="Đổi mật khẩu">
          <div className="md:w-2/5 mb-4 mx-auto">
            <Form>
              <div>
                <div>Mật khẩu cũ</div>
                <Form.Item
                  name="oldPassword"
                  className="h-18 pb-1"
                  validateStatus={error.oldPassword ? 'error' : undefined}
                  help={error.oldPassword ? 'Vui lòng nhập mật khẩu cũ' : undefined}
                >
                  <Input.Password
                    size="large"
                    name="oldPassword"
                    className="rounded"
                    placeholder="Mật khẩu cũ"
                    prefix={<SafetyOutlined className="pr-1" />}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={handleOnChange}
                  />
                </Form.Item>
              </div>
              <div>
                <div>Mật khẩ mới</div>
                <Form.Item
                  name="password"
                  className="h-18 pb-1"
                  validateStatus={error.password ? 'error' : undefined}
                  help={error.password ? 'Vui lòng nhập mật khẩu mới' : undefined}
                >
                  <Input.Password
                    size="large"
                    name="password"
                    className="rounded"
                    placeholder="Mật khẩu mới"
                    prefix={<UnlockOutlined className="pr-1" />}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={handleOnChange}
                  />
                </Form.Item>
              </div>
              <div>
                <div>Xác nhận mật khẩu</div>
                <Form.Item
                  name="confirmPassword"
                  className="h-18 pb-1"
                  validateStatus={error.confirmPassword ? 'error' : undefined}
                  help={error.confirmPassword ? error.confirmPassword : undefined}
                >
                  <Input.Password
                    size="large"
                    name="confirmPassword"
                    className="rounded"
                    placeholder="Xác nhận mật khẩu mới"
                    prefix={<LockOutlined className="pr-1" />}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={handleOnChange}
                  />
                </Form.Item>
              </div>
              <div className="mt-5 text-right">
                <Button className="rounded h-9 w-24" type="primary" htmlType="submit" onClick={onSubmit}>
                  OK
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </Spin>
    </div>
  );
}

export default ChangePassword;
