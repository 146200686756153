import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React from 'react';
import api from '../utils/axios';
import popup from '../utils/popup';

const Login = ({ history }) => {
  const [body, setBody] = React.useState({});
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();


  const onChange = (e) => {
    setError({});
    setBody({ ...body, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    try {
      // if (!body.username && !body.password) return setError({ password: true, name: true });
      // if (!body.username) return setError({ ...error, name: true });
      // if (!body.password) return setError({ ...error, password: true });
      setLoading(true);
      let bodies = {
        username: form.getFieldValue('username'),
        password: form.getFieldValue('password')
      };
      const { data } = await api.post('/api/v1/user/login', bodies);
      localStorage.setItem('token', data.accessToken.id);
      delete data.accessToken;
      popup.success('Login successful');
      setLoading(false);
      history.push('/dashboard');
    } catch (err) {
      setLoading(false);
      console.log(err);
      popup.error('Username hoặc mật khẩu không đúng hoặc User không tồn tại !');
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-full xl:w-1/4 lg:w-1/3 md:w-2/4 sm:w-2/3 bg-white rounded-lg p-6 mx-4">
        <div className="flex font-bold justify-center mb-4">
          <img
            alt="avatar"
            className="h-20 w-20"
            src="https://raw.githubusercontent.com/sefyudem/Responsive-Login-Form/master/img/avatar.svg"
          />
        </div>
        <h1 className="text-3xl text-center text-gray-700 mb-4">Mobitopup CMS</h1>
        <div className="max-w-lg m-auto px-6 ">
          <Form form={form}>
            <div className="w-full">
              <Form.Item
                name="username"
                className="h-18 pb-1"
                validateStatus={error.name ? 'error' : undefined}
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: `Vui lòng nhập tên đăng nhập`
                  }
                ]}
              >
                <Input
                  size="large"
                  className="rounded"
                  prefix={<UserOutlined className="pr-1" />}
                  placeholder="Username"
                  onChange={onChange}
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                name="password"
                className="h-18 pb-1"
                validateStatus={error.password ? 'error' : undefined}
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: `Vui lòng nhập mật khẩu`
                  }
                ]}
                help={error.password ? 'Vui lòng nhập mật khẩu' : undefined}
              >
                <>
                  <Input.Password
                    size="large"
                    className="rounded"
                    placeholder="Mật khẩu"
                    prefix={<LockOutlined className="pr-1" />}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={onChange}
                  />
                </>
              </Form.Item>
            </div>
            <Form.Item className="text-center">
              <Button type="primary" size="large" onClick={onSubmit} loading={loading} htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
