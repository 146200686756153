import React, {useEffect, useState} from 'react';
import {Checkbox, Modal, Table, Typography} from 'antd';
import api from '../../utils/axios';

const {Text} = Typography;

const B2BDataModal = ({visible, onCancel, getListData, openNotification}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (visible) {
      getB2BData();
    } else {
      setSelectedRecord([]);
    }
  }, [visible]);

  const [selectedRecord, setSelectedRecord] = useState([]);
  const [confirmVisible, setConfirmVisible] = useState();

  const getB2BData = async () => {
    await api
      .get('/api/v1/mobileData/listSyncMobileData')
      .then(data => setData(data.data));
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 150,
    },
    {
      title: 'Tên gói',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 150,
    },
    {
      title: 'Thời hạn',
      dataIndex: 'durationDay',
      key: 'durationDay',
      width: 100,
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 100,
    },
    {
      title: 'Kho',
      dataIndex: 'storage',
      key: 'storage',
      width: 100,
    },
    {
      title: 'Giảm giá',
      dataIndex: 'discountRate',
      key: 'discountRate',
      width: 100,
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      title: 'Gọi nội mạng',
      dataIndex: 'internalCallTotalHour',
      key: 'internalCallTotalHour',
      width: 100,
    },
    {
      title: 'Gọi ngoại mạng',
      dataIndex: 'externalCallTotalHour',
      key: 'externalCallTotalHour',
      width: 100,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (text, record) => {
        return <Text ellipsis={true}>{text}</Text>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <span>
          <Checkbox
            value={selectedRecord.indexOf(record) !== -1}
            onChange={e => onCheckRecord(e, record)}
          />
        </span>
      ),
    },
  ];

  const onCheckRecord = (e, record) => {
    const {checked} = e.target;
    if (checked) {
      selectedRecord.push(record);
    } else {
      const newSelectedRecord = [...selectedRecord];
      const selectedIndex = newSelectedRecord.indexOf(record);
      if (selectedIndex > -1) newSelectedRecord.splice(selectedIndex, 1);
      setSelectedRecord(newSelectedRecord);
    }
  };

  const onAddRecord = async () => {
    if (selectedRecord.length > 0) {
      try {
        await api
          .post('api/v1/mobileData/importArray', {data: selectedRecord})
          .then(res => {
            if (res.passedData?.length !== 0) {
              openNotification('success', `Bạn đã thêm gói thành công`);
            } else {
              openNotification('failed', `Bạn đã thêm gói thất bại`);
            }
          });
      } catch (e) {
        openNotification(
          'failed',
          `Bạn đã thêm gói thất bại, mã lỗi: ${e.error.message}`,
        );
      }
      setConfirmVisible(false);
      getListData();
      onCancel();
    } else {
      setConfirmVisible(false);
    }
  };

  const onOk = () => {
    setConfirmVisible(true);
  };

  const renderConfirmContent = () => {
    return selectedRecord.length > 0 ? (
      <div>
        <p>Bạn có chắc chắn muốn thêm các gói: </p>
        {selectedRecord.map(item => {
          return <p>{`tên ${item?.name} - code ${item?.code}`}</p>;
        })}
      </div>
    ) : (
      <div>
        <p>Chưa có gói cước được chọn</p>
      </div>
    );
  };

  return (
    <Modal
      width={2100}
      okText={'Thêm'}
      onOk={onOk}
      onCancel={onCancel}
      title={'Thêm gói data từ B2B'}
      open={visible}>
      <Table dataSource={data} columns={columns} scroll={{x: 2000, y: 900}} />
      <Modal
        title={'Xác nhận'}
        onCancel={() => setConfirmVisible(false)}
        onOk={onAddRecord}
        open={confirmVisible}>
        {renderConfirmContent()}
      </Modal>
    </Modal>
  );
};

export default B2BDataModal;
