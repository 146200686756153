import { message, Form } from 'antd';
import { useState } from 'react';
import { Context } from '../../../context';
import moment from 'moment';
import api from '../../../utils/axios';
import { isEmpty } from 'lodash';
import { debounce } from 'lodash';
import { ReportLayout } from '../Views/ReportLayout';

const Model = () => {
    const { user } = Context();
    const ExcelJS = require('exceljs');
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [modal, setModal] = useState(false);
    const [partnerList, setPartnerList] = useState([]);
    const [simList, setSimList] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const [actionForm] = Form.useForm();


    const getList = async (values) => {
        try {
            setLoading(true);
            await api.get("/api/v1/kit/transaction/list", { params: { ...values } }).then((res) => {
                setList(res?.data);
                setTotal(res?.paging?.total);
                setLoading(false);
            })
        } catch (error) {
            console.log(error);
            message.error({
                content: `Lỗi lấy danh sách: ${error?.error?.message}`,
                duration: 5
            })
            setLoading(false)
        }
    };

    const getPartnerList = async () => {
        try {
            await api.get('/api/v1/kit/partner/list', {
                params: { limit: 1000 },
            }).then((res) => {
                let data = [
                    {
                        id: 'Tất cả',
                        name: 'Tất cả',
                        code: 'Tất cả',
                    },
                    ...res?.data
                ]
                setPartnerList(data)
            });
        } catch (error) {
            console.log(error)
        }
    };

    const getPacakgeList = async () => {
        try {
            //Calling api here (later on)
            await api.get('/api/v1/kit/package/list').then((res) => {
                let data = [
                    {
                        name: 'Tất cả',
                        code: 'Tất cả'
                    },
                    ...res?.data
                ];
                setPackageList(data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleActions = async (values, action, info) => {
        switch (action) {
            case 'edit':
                try {
                    setActionLoading(true);
                    let body = {
                        ...values,
                        number: values?.number === info?.number ? undefined : values?.number
                    };
                    await api.post('/api/v1/kit/update', body).then(async () => {
                        message.success({
                            content: 'Cập nhật thông tin thành công !',
                            duration: 4
                        });
                        setActionLoading(false);
                        await getList({
                            page: 0,
                            limit: 10,
                            start: moment().add(-15, 'days').format('DD-MM-YYYY'),
                            end: moment().format('DD-MM-YYYY')
                        });
                        actionForm.resetFields();
                        setModal(false)
                    })
                } catch (error) {
                    console.log(error);
                    message.error({
                        content: `Lỗi cập nhật thông tin: ${error?.error?.message}`,
                        duration: 5
                    })
                    setActionLoading(false);
                };
                break;
            case 'create':
                try {
                    setActionLoading(true);
                    let packageCode = packageList?.filter((item) => item?.name === values?.package);
                    let body = {
                        ...values,
                        package: packageCode[0]?.code
                    }
                    await api.post('/api/v1/kit/create', body).then(async () => {
                        message.success({
                            content: 'Thêm thông tin thành công !',
                            duration: 5
                        });
                        setActionLoading(false);
                        await getList({
                            page: 0,
                            limit: 10,
                            start: moment().add(-15, 'days').format('DD-MM-YYYY'),
                            end: moment().format('DD-MM-YYYY')
                        });
                        actionForm.resetFields();
                        setSimList([]);
                        setModal(false);
                    })
                } catch (error) {
                    console.log(error);
                    message.error({
                        content: `Lỗi tạo mới thông tin: ${error?.error?.message}`,
                        duration: 5
                    });
                    setActionLoading(false);
                };
                break;
        }
    };

    const getSimList = debounce(async (value) => {
        try {
            const res = await api.get("/api/v1/transaction/sim/findSim", { params: { keyword: value, page: 0, limit: 20 } });
            setSimList(res?.data);
        } catch (error) {
            console.log(error);
        }
    }, 1000);

    const CreateFile = async (params) => {
        const workbook = new ExcelJS.Workbook();
        try {
            let res = await api.get(
                '/api/v1/report/kit/list-trans',
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',

                    },
                    params: { ...params },
                },
            );
            await ReportLayout(workbook, 'Lich_su_dang_ky_Bo_Kit', params, res?.data, user?.isAdmin);
            const buff = await workbook.xlsx.writeBuffer();
            return buff;
        } catch (error) {
            console.log(error)
        }
    }

    const handleExport = async (value) => {
        try {
            setExportLoading(true);
            const res = await CreateFile(value)
            if (res) {
                const file = new Blob([res], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const fileUrl = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = `Lich_su_dang_ky_Bo_Kit.xlsx`;
                link.click();
                setExportLoading(false);
            }
        } catch (error) {
            console.log(error);
            setExportLoading(false);
            message.error({
                content: `Có lỗi hệ thống xuất Báo cáo: ${error?.error?.message}`,
                duration: 5,
            });
        }
    };

    return {
        list,
        loading,
        modal,
        total,
        actionLoading,
        exportLoading,
        actionForm,
        packageList,
        getPacakgeList,
        getSimList,
        simList,
        handleExport,
        setModal,
        getList,
        handleActions,
        partnerList,
        getPartnerList,
    }
}

export default Model