import {
    AuditOutlined,
    FileSearchOutlined,
    GroupOutlined,
    LoadingOutlined,
    LockOutlined,
    MailOutlined,
    PhoneOutlined,
    SmileOutlined,
    UserAddOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select, Spin, Table, Tooltip, message } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Context } from '../../context';
import api from '../../utils/axios';
import { getUserStatus, getUserType } from '../../utils/helper';
import ResetPassword from './ActionComponents/resetPassword';
import UserDetails from './ActionComponents/userDetails';

const UserManagement = () => {
    const [form] = Form.useForm();
    const [formDetail] = Form.useForm();
    const { user } = Context();
    const [list, setList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [partnerList, setPartnerList] = useState([])
    const [detailInfo, setDetailInfo] = useState({});
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [detailModal, setDetailModal] = useState(false);
    const [resetModal, setResetModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [paramExport, setParamExport] = useState({
        page: 0,
        limit: 10
    })
    const [loading, setLoading] = useState(false);

    const columnsTable = [
        {
            title: 'STT',
            width: '5%',
            render: (item, record, index) => (
                <span style={{ fontWeight: 'bold' }}>{index + 1}</span>
            ),
            align: 'center',
        },
        {
            title: 'Username',
            width: '12%',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
        },
        {
            title: 'Họ tên',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '10%',
            align: 'center',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '17%',
            align: 'center',
            render: (text) => <span>{isEmpty(text) ? 'Không có Email' : text}</span>
        },
        {
            title: 'Đối tác',
            dataIndex: 'partner',
            key: 'partner',
            width: '13%',
            align: 'center',
            render: (text, record) => (<span>{isEmpty(text) ? 'Không có đối tác' : record?.partner?.name[0]?.toUpperCase() + record?.partner?.name?.slice(1)}</span>)
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            width: '10%',
            key: 'phone',
            align: 'center',
        },
        {
            title: 'Số dư',
            dataIndex: 'balance',
            key: 'balance',
            width: '15%',
            align: 'center',
            render: (text) => <span className={text === 0 ? 'text-red-700 font-bold' : 'text-green-700 font-bold'}>{new Intl.NumberFormat().format(text)} VND</span>
        },
        {
            title: 'Role User',
            dataIndex: 'roles',
            key: 'roles',
            width: '9%',
            align: 'center',
            render: (text, record) => getUserType(text[0]?.code)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '9%',
            render: (text) => getUserStatus(text)
        },
        {
            title: 'Hành động',
            key: 'action',
            align: 'center',
            width: '8%',
            fixed: 'right',
            render: (text, record) => (
                <div className="flex justify-center items-center gap-x-3">
                    <Tooltip title='Chi tiết User'>
                        <Button type='primary' className='flex justify-center items-center' shape="circle" icon={<FileSearchOutlined />} onClick={() => handleDetail(record, 'openEdit')} />
                    </Tooltip>
                    <Tooltip title='Đặt mật khẩu mới'>
                        <Button type='default' className='flex justify-center items-center' shape="circle" icon={<LockOutlined />} onClick={() => handleDetail(record, 'reset')}></Button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const getList = async (params) => {
        try {
            setLoading(true);
            const res = await api.get('/api/v1/user/list', { params: { ...params } });
            if (res) {
                setList(res?.data);
                setTotal(res?.pagination?.total);
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getPartnerList = async () => {
        try {
            await api.get('/api/v1/partner/list', { params: { limit: 1000 } }).then((res) => {
                setPartnerList([
                    {
                        id: "Tất cả",
                        name: "Tất cả"
                    },
                    ...res?.data
                ]);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleDetail = (info, action) => {
        if (action === 'openEdit') {
            formDetail.resetFields();
            setModalLoading(true);
            setDetailModal(true);
            setDetailInfo(info);
            setModalLoading(false);
        }
        else if (action === "openCreate") {
            formDetail.resetFields();
            setModalLoading(true);
            setDetailModal(true);
            setDetailInfo({});
            setModalLoading(false);
        }
        else if (action === 'close') {
            setDetailModal(false);
            setDetailInfo({});
            formDetail.resetFields();
        }
        else if (action === 'reset') {
            formDetail.resetFields();
            setModalLoading(true);
            setResetModal(true)
            setDetailInfo(info);
            setModalLoading(false)
        }
    };

    useEffect(() => {
        if (user?.isAdmin === true) {
            getList(paramExport);
        }
    }, [user]);

    useEffect(() => {
        getPartnerList();
        getRoleList();
    }, [])

    const handleSearch = async (values) => {
        let params = {
            userName: values?.userName === "" ? undefined : values?.userName,
            phone: values?.phone === "" ? undefined : values?.phone,
            email: values?.email === "" ? undefined : values?.email,
            partner: values?.partner === "" || values?.partner === "Tất cả" ? undefined : values?.partner,
            roles: values?.roles === "" || values?.roles === "Tất cả" ? undefined : values?.roles,
            status: values?.status === "" || values?.status === "Tất cả" ? undefined : values?.status,
            page: 0,
            limit: pageSize
        }
        setParamExport(params);
        setPage(0);
        await getList(params)
    };

    const getRoleList = async () => {
        try {
            await api.get('/api/v1/role/list', { params: { limit: 1000 } }).then((res) => {
                let data = [
                    {
                        name: 'Tất cả',
                        code: 'Tất cả'
                    },
                    ...res?.data
                ]
                setRoleList(data);
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleDetailAction = async (info, action, userList) => {
        switch (action) {
            case "update":
                try {
                    setModalLoading(true);
                    await api.put('/api/v1/user/update-user', info).then(async () => {
                        message.success({
                            content: 'Cập nhật thành công !',
                            duration: 4
                        });
                        setModalLoading(false);
                        setDetailModal(false);
                        formDetail.resetFields();
                        await getList({
                            page: 0,
                            limit: 10
                        });
                    });

                } catch (error) {
                    message.error({
                        content: `Có lỗi xảy ra: ${error?.error?.message}`,
                        duration: 4
                    });
                    setModalLoading(false);
                };
                break;
            case "create":
                try {
                    setModalLoading(true);
                    await api.post('/api/v1/user/create-user', info).then(async () => {
                        message.success({
                            content: 'Tạo mới thành công !',
                            duration: 4
                        });
                        setModalLoading(false);
                        setDetailModal(false);
                        formDetail.resetFields();
                        await getList({
                            page: 0,
                            limit: 10
                        });
                    })
                } catch (error) {
                    message.error({
                        content: `Có lỗi xảy ra: ${error?.error?.message}`,
                        duration: 4
                    });
                    setModalLoading(false);
                }
                break;
            case "reset-pass":
                try {
                    setModalLoading(true);
                    await api.put('/api/v1/user/reset-password', info).then(async () => {
                        message.success({
                            content: 'Đặt lại mật khẩu mới thành công !',
                            duration: 5
                        });
                        setModalLoading(false);
                        formDetail.resetFields();
                        setResetModal(false);
                        await getList({
                            page: 0,
                            limit: 10
                        });
                    })
                } catch (error) {
                    message.error({
                        content: `Có lỗi xảy ra: ${error?.error?.message}`,
                        duration: 4
                    });
                    setModalLoading(false);
                }
        }
    };

    const handleTableChange = async (pagination) => {
        setPage(pagination?.current - 1);
        setPageSize(pagination?.pageSize);
        let params = {
            ...paramExport,
            page: pagination?.current - 1,
            limit: pagination?.pageSize
        };
        await getList(params)
    };

    return (
        <div style={{
            flex: 1,
            height: '100vh',
            marginBottom: 100,
        }} className='px-3 py-3'>
            <Card
                title="Quản lý User"
                className="shadow-2xl"
                extra={[
                    <Button type='link' className='bg-green-600 flex justify-center items-center text-center text-white font-semibold' onClick={() => handleDetail(detailInfo, "openCreate")} icon={<UserAddOutlined />}>Thêm</Button>
                ]}
            >
                <Form form={form} onFinish={handleSearch}>
                    <Row gutter={[6, 0]} className="flex justify-center items-center">
                        <Col lg={4} span={24}>
                            <div>Username</div>
                            <Form.Item name={'userName'}>
                                <Input placeholder='Nhập username' className='rounded-lg border-gray-300' prefix={<UserOutlined className='pr-1' />} />
                            </Form.Item>
                        </Col>
                        <Col lg={4} span={24}>
                            <div>Email</div>
                            <Form.Item name='email' rules={[
                                {
                                    type: 'string'
                                }
                            ]}>
                                <Input placeholder='Nhập Email' className='rounded-lg border-gray-300' prefix={<MailOutlined className='pr-1' />} />
                            </Form.Item>
                        </Col>
                        <Col lg={4} span={24}>
                            <div>Số điện thoại</div>
                            <Form.Item name={'phone'}>
                                <Input placeholder='Nhập số điện thoại' className='rounded-lg border-gray-300' prefix={<PhoneOutlined className='pr-1' />} />
                            </Form.Item>
                        </Col>
                        <Col lg={4} span={24}>
                            <div>Đối tác</div>
                            <Form.Item name={'partner'}>
                                <Select defaultValue={"Tất cả"} suffixIcon={<AuditOutlined className='pl-1' />}>
                                    {partnerList?.map((item) => (
                                        <Select.Option key={item?.id} value={item?.id}>{item?.name[0]?.toUpperCase() + item?.name?.slice(1)}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4} span={24}>
                            <div>Role User</div>
                            <Form.Item name='roles'>
                                <Select
                                    defaultValue={"Tất cả"}
                                    suffixIcon={<GroupOutlined className='pl-1' />}
                                    options={roleList?.map((item) => {
                                        return {
                                            label: item?.name[0]?.toUpperCase() + item?.name?.slice(1),
                                            value: item?.code
                                        }
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4} span={24}>
                            <div>Trạng thái</div>
                            <Form.Item name='status'>
                                <Select
                                    defaultValue={"Tất cả"}
                                    suffixIcon={<SmileOutlined className='pl-1' />}
                                    options={[
                                        {
                                            label: 'Tất cả',
                                            value: ""
                                        },
                                        {
                                            label: 'Active',
                                            value: 0,
                                        },
                                        {
                                            label: 'Inactive',
                                            value: 1,
                                        },
                                        {
                                            label: 'Deleted',
                                            value: 2
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} span={12}>
                            <Form.Item>
                                <Button type='primary' className='w-full' htmlType='submit'>
                                    Tra cứu
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col lg={12} span={12}>
                            <Form.Item>
                                <Button danger type='primary' className='w-full' onClick={async () => {
                                    form.resetFields();
                                    await getList({
                                        page: 0,
                                        limit: 10
                                    })
                                }}>
                                    Xóa bộ lọc
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[8, 8]} className="flex justify-center items-center">
                    <Col span={24} className="mt-1">
                        <Spin
                            spinning={loading}
                            indicator={
                                <LoadingOutlined className="text-blue-600 font-semibold" />
                            }
                            tip={<span className="font-semibold">Loading</span>}>
                            <Table
                                columns={columnsTable}
                                rowKey={record => record.id}
                                dataSource={list}
                                onChange={handleTableChange}
                                scroll={{ x: 1600, y: 1000 }}
                                className="h-full"
                                pagination={{
                                    pageSize,
                                    current: page + 1,
                                    total,
                                    showSizeChanger: true,
                                    pageSizeOptions: ['10', '20', '50', '100'],
                                }}
                                summary={() => (
                                    <Table.Summary fixed={'bottom'}>
                                        <Table.Summary.Row className="w-full">
                                            <Table.Summary.Cell
                                                index={0}
                                                colSpan={2}
                                                className="text-sm">
                                                Tổng User:{' '}
                                                <span className="font-bold ml-2 text-blue-700">
                                                    {total}
                                                </span>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )}
                            />
                        </Spin>
                    </Col>
                </Row>
            </Card>
            <UserDetails loading={modalLoading} info={detailInfo} open={detailModal} formDetail={formDetail} partnerList={partnerList} handleDetail={handleDetail} handleDetailAction={handleDetailAction} roleList={roleList} userList={list} />
            <ResetPassword loading={modalLoading} info={detailInfo} open={resetModal} form={formDetail} setModal={setResetModal} handleDetailAction={handleDetailAction} />
        </div>
    )
}

export default UserManagement;