import Model from '../Model';

const ViewModel = () => {
    const { list,
        loading,
        modal,
        total,
        actionLoading,
        exportLoading,
        packageList,
        getPacakgeList,
        getSimList,
        simList,
        handleExport,
        actionForm,
        setModal,
        getList,
        handleActions,
        partnerList,
        setSimNumber,
        getPartnerList } = Model();
    return {
        list,
        loading,
        modal,
        total,
        actionLoading,
        exportLoading,
        packageList,
        getPacakgeList,
        actionForm,
        getSimList,
        simList,
        handleExport,
        setModal,
        getList,
        handleActions,
        partnerList,
        getPartnerList,
    }
}

export default ViewModel