import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Form, Input, Modal, Spin, message } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';

const ResetPassword = (props) => {
    const { loading, form, info, setModal, handleDetailAction, open } = props;

    const handleAction = async (values) => {
        let action = 'reset-pass';
        if (values?.newPassword === info?.password) {
            message.error({
                content: 'Mật khẩu mới và mật khẩu cũ không được trùng nhau !',
                duration: 5
            });
        }
        else {
            let body = {
                phone: values?.phone,
                newPassword: values?.newPassword
            };
            await handleDetailAction(body, action);
        }
    }
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#00b96b',
                },
            }}
        >
            <Modal open={open} centered destroyOnClose={true} closable={false} footer={null} title="Đặt mật khẩu mới" closeIcon={null}>

                <Spin spinning={loading} indicator={<LoadingOutlined />} tip='Loading...'>
                    <Form form={form} onFinish={handleAction} labelCol={{
                        span: 8,
                    }}
                        wrapperCol={{
                            span: 16
                        }}>
                        <div className='p-2'>
                            <Form.Item name={"phone"} initialValue={info?.phone} label='Số điện thoại' rules={[
                                {
                                    required: true,
                                    type: "string",
                                    message: "Số điện thoại không để trống !"
                                }
                            ]}>
                                <Input suffix={<PhoneOutlined className="pr-1" />} className='pointer-events-none' />
                            </Form.Item>
                            <Form.Item name={"email"} initialValue={!isEmpty(info?.email) ? info?.email : null} label='Email user' rules={[
                                {
                                    required: false,
                                    type: "string",
                                }
                            ]}>
                                <Input suffix={<MailOutlined className="pr-1" />} placeholder='Nhập Email user ( không bắt buộc )' className='pointer-events-none'/>
                            </Form.Item>
                            <Form.Item name={'newPassword'} label='Mật khẩu mới' rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    message: 'Mật khẩu mới không để trống !'
                                }
                            ]}>
                                <Input.Password placeholder='Nhập mật khẩu mới...' />
                            </Form.Item>
                            <Form.Item
                                name="confirm"
                                label="Xác nhận mật khẩu"
                                dependencies={['newPassword']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng xác nhận mật khẩu mới !',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Mật khẩu xác nhận không trùng khớp !'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder='Vui lòng xác nhận mật khẩu mới...'/>
                            </Form.Item>
                        </div>
                        <div className='flex justify-center items-center gap-3'>
                            <Form.Item>
                                <Button type='primary' htmlType='submit'>Xác nhận</Button>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={() => {
                                    form.resetFields();
                                    setModal(false);
                                }} type='default'>Quay lại</Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </ConfigProvider>
    )
}

export default ResetPassword