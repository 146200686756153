import { ROLE } from '../constants';

export const DataFormater = number => {
  if (number >= 1000000000) {
    return (number / 1000000000).toString() + 'B';
  } else if (number >= 1000000) {
    return (number / 1000000).toString() + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toString() + 'K';
  } else {
    return number.toString();
  }
};

export const getRoleText = value => {
  switch (value) {
    case ROLE.MERCHANT:
      return 'Merchant';
    default:
      return 'Admin';
  }
};

export const getStatus = value => {
  switch (value) {
    case 0:
      return <span style={{ color: '#d9a61c', fontWeight: 700 }}>Khởi tạo</span>;
    case 1:
      return (
        <span style={{ color: '#2029a8', fontWeight: 700 }}>Đang xử lý</span>
      );
    case 2:
      return <span style={{ color: 'green', fontWeight: 700 }}>Hoàn thành</span>;
    case 3:
      return <span style={{ color: 'red', fontWeight: 700 }}>Lỗi</span>;
    case 4:
      return <span style={{ color: '#730d34', fontWeight: 700 }}>Đã xóa</span>;
    case 5:
      return <span style={{ color: '#c79712', fontWeight: 700 }}>Chờ xác nhận</span>;
    default:
      return '';
  }
};

export const statusForDetails = value => {
  switch (value) {
    case 0:
      return 'Khởi tạo';
    case 1:
      return 'Đang xử lý';
    case 2:
      return 'Hoàn thành';
    case 3:
      return 'Lỗi';
    case 4:
      return 'Đã xóa';
    case 5:
      return 'Chờ xác nhận';
    default:
      return '';
  }
};

export const statusForContent = (value) => {
  switch(value) {
    case 0:
      return <span className='text-green-600 font-bold'>Hoạt động</span>
    case 1:
      return <span className='text-red-700 font-bold'>Không hoạt động</span>
  }
}

export const statusForSimApplication = value => {
  switch (value) {
    case 10:
      return 'Chờ thanh toán';
    case 11:
      return 'Chờ giao hàng';
    case 12:
      return 'Chờ nhận hàng';
    case 13:
      return 'Giao hàng thành công';
    case 14:
      return 'Đã hủy';
    case 15:
      return 'Đã hòa mạng';
    case 16:
      return 'Hòa mạng thất bại'
  }
}

export const formatCurrency = value => {
  return `${new Intl.NumberFormat().format(value)} VND`;
};

export const getUserType = (value) => {
  switch (value) {
    case 'user':
      return <span style={{ color: '#473b3b', fontWeight: 700 }}>User</span>;
    case 'admin':
      return <span style={{ color: '#180d7a', fontWeight: 700 }}>Admin</span>;
    case 'partner':
      return <span style={{ color: '#650d7a', fontWeight: 700 }}>Partner</span>;
  }
}

export const getUserStatus = (value) => {
  switch (value) {
    case 0:
      return <span style={{ color: '#32a852', fontWeight: 700 }}>Active</span>;
    case 1:
      return <span style={{ color: '#b37f27', fontWeight: 700 }}>Inactive</span>;
    case 2:
      return <span style={{ color: '#ab1111', fontWeight: 700 }}>Deleted</span>;
  }
}
