import { LoadingOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const UserDetails = (props) => {
  const { open, loading, info, formDetail, partnerList, handleDetail, handleDetailAction, roleList } = props;

  const convertToDate = value => {
    return moment(value).format("YYYY-MM-DD");
  };

  const initialDate = _.get(info, "birthDay")
    ? dayjs(convertToDate(info?.birthDay))
    : dayjs(convertToDate(new Date()));

  const handleClose = async () => {
    let type = 'close';
    let value = {}
    formDetail.resetFields();
    await handleDetail(value, type);
  };

  const convertToISO = dateStr => {
    const year = dateStr.$y;
    const month = dateStr.$M + 1;
    const day = dateStr.$D;
    const isoDate = `${year
      .toString()
      .padStart(4, "0")}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}T00:00:00.000Z`;
    return isoDate;
  };

  const handleActions = async (values) => {
    let body = {
      ...values,
      birthDay: convertToISO(values?.birthDay),
      partner: values?.partner === "" || values?.partner === "Tất cả" || values?.partner === null ? undefined : values?.partner,
      description: values?.description === "" || values?.description === null ? undefined : values?.description,
      address: values?.address === "" || values?.address === null ? undefined : values?.address,
      phone: !!info?.id ? info?.phone : values?.phone
    };



    if (!!info?.id) {
      await handleDetailAction(body, "update");
    }
    else {
      await handleDetailAction(body, "create");
    }
  }
  return (
    <Modal open={open} centered closable={false} destroyOnClose={true} footer={null} title={info?.id ? 'Cập nhật User' : 'Thêm mới User'} >
      <Spin spinning={loading} indicator={<LoadingOutlined />} tip={'Loading...'}>
        <Form form={formDetail} onFinish={handleActions} className='flex flex-col'>
          <Form.Item name={'userName'} label='Tên Username' initialValue={!!info?.id ? _.get(info, 'userName') : null} rules={[
            {
              required: true,
              type: "string",
              message: "username không được để trống !"
            }
          ]}>
            <Input placeholder='Nhập tên username' className='rounded-lg border-gray-300' />
          </Form.Item>
          {!info?.id && (
            <Form.Item name={'password'} label='Mật khẩu' rules={[
              {
                required: true,
                type: "string",
                message: "Vui lòng nhập mật khẩu để xác minh"
              }
            ]}>
              <Input.Password placeholder='Nhập mật khẩu' size='large' className='rounded-lg border-gray-300' />
            </Form.Item>
          )}
          <Form.Item name={'email'} label='Email' initialValue={!!info?.id ? _.get(info, 'email') : null} rules={[
            {
              required: true,
              type: "email",
              message: "Vui lòng nhập đúng định dạng email"
            }
          ]}>
            <Input placeholder='example@gmail.com' className='rounded-lg border-gray-300' />
          </Form.Item>
          <Form.Item name={'fullName'} label='Họ tên' initialValue={!!info?.id ? _.get(info, 'fullName') : null} rules={[
            {
              required: true,
              type: "string",
              message: "Vui lòng nhập họ tên là chữ"
            }
          ]}>
            <Input placeholder='Nhập họ tên' className='rounded-lg border-gray-300' />
          </Form.Item>
          <Form.Item name={'address'} label='Địa chỉ' initialValue={!!info?.id ? _.get(info, 'address') : null} rules={[
            {
              required: false,
              type: "string",
              message: "Vui lòng nhập họ tên là chữ"
            }
          ]}>
            <Input.TextArea placeholder='Nhập địa chỉ' size='large' autoSize={true} className='overflow-hidden' />
          </Form.Item>
          <Form.Item
            label="Ngày sinh"
            name={"birthDay"}
            initialValue={initialDate}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              size='large'
              style={{ width: "100%" }}
              placeholder="Chọn ngày sinh"
            />
          </Form.Item>
          {!info?.id && (
            <Form.Item label="Số điện thoại" name={'phone'} initialValue={!!info?.id ? _.get(info, 'phone') : null} rules={[
              {
                required: true,
                type: "string",
                len: 10,
                message: "Vui lòng nhập số điện thoại gồm 10 chữ số"
              }
            ]}>
              <Input placeholder='Nhập số điện thoại' maxLength={10} className='rounded-lg border-gray-300' />
            </Form.Item>
          )}
          <Form.Item label="Mô tả" name={'description'} initialValue={!!info?.id ? _.get(info, 'description') : null}>
            <Input.TextArea placeholder='Nhập mô tả' style={{ width: '100%' }} size='large' autoSize={true} className='overflow-hidden' />
          </Form.Item>
          <Form.Item label="Đối tác" name={'partner'} rules={[
            {
              required: false,
              type: 'string',
              message: "Vui lòng chọn đối tác"
            }
          ]}>
            <Select size='large' defaultValue={!!info?.id && !!info?.partner?.name ? info?.partner?.name[0]?.toUpperCase() + info?.partner?.name?.slice(1) : 'Có thể chọn đối tác (Không bắt buộc)'}>{partnerList?.map((item) => (
              item?.id !== "Tất cả" && (<Select.Option key={item?.id} value={item?.id}>{item?.name[0]?.toUpperCase() + item?.name?.slice(1)}</Select.Option>)
            ))}</Select>
          </Form.Item>
          <Form.Item label="Role" name={'roles'} rules={[
            {
              required: true,
              type: 'string',
              message: "Vui lòng chọn vai trò"
            }
          ]} initialValue={!!info.id ? info?.roles[0]?.name : 'Chưa có thông tin vai trò, vui lòng chọn vai trò'}>
            <Select size='large' defaultValue={!!info?.id && !!info?.roles[0]?.name ? info?.roles[0]?.name[0]?.toUpperCase() + info?.roles[0]?.name?.slice(1) : 'Chưa có thông tin vai trò, vui lòng chọn vai trò'}>{roleList?.map((item) => (
              item?.name !== "Tất cả" && (<Select.Option key={item?.code} value={item?.code}>{item?.name[0]?.toUpperCase() + item?.name?.slice(1)}</Select.Option>)
            ))}</Select>
          </Form.Item>
          <Form.Item label="Trạng thái" name={'status'} initialValue={!!info?.id ? _.get(info, 'status') : 'Vui lòng chọn trạng thái User'} rules={[
            {
              required: true,
              type: 'number',
              message: "Vui lòng chọn trạng thái cho User"
            }
          ]}>
            <Select size='large' defaultValue={!!info?.id && _.get(info, 'status')} options={[
              {
                label: 'Active',
                value: 0
              },
              {
                label: 'Inactive',
                value: 1
              },
            ]} />
          </Form.Item>
          <Form.Item label="Số dư" name={'balance'} initialValue={!!info?.id ? _.get(info, 'balance') : 0} rules={[
            {
              required: true,
              type: 'number',
              message: `Vui lòng nhập số tiền lớn hơn hoặc bằng ${new Intl.NumberFormat().format(
                1000
              )} VND`
            }
          ]}>
            <InputNumber
              placeholder="Nhập hạn mức mới"
              size='large'
              style={{ width: "100%" }}
              suffix={<span className='text-green-800 font-semibold'>VND</span>}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
          <div className='flex justify-center items-center gap-3'>
            <Form.Item>
              <Button type='primary' htmlType='submit'>{!!info?.id ? 'Cập nhật' : 'Thêm mới'}</Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={handleClose} type='default'>Quay lại</Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  )
}

export default UserDetails;