import React, { useEffect } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import Login from './pages/Login';
import PrivateRoute from './components/Layout/PrivateRoute';
import NotFound from './pages/NotFound';
import routes from './routes';
import { ContextProvider } from './context';
import { focusHandling } from 'cruip-js-toolkit';

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <Switch>
      <Route exact path="/login" component={Login}></Route>
      <ContextProvider>
        {routes.map((item, index) => (
          <PrivateRoute exact path={item.path} key={index}>
            {item.component}
          </PrivateRoute>
        ))}
      </ContextProvider>
      <Route path="/404" component={NotFound} />
      <Redirect from="*" to="/404" />
    </Switch>
  );
}

export default App;
