/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export default function Notfound({ history }) {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404"></div>
        <h1>404</h1>
        <h2>Oops! Page Not Be Found</h2>
        <p>
          Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily
          unavailable
        </p>
        <a onClick={() => history.push('/')}>Back to homepage</a>
      </div>
    </div>
  );
}
