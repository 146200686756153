import { FileSearchOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Context } from '../../../context';
import ViewModel from '../ViewModel';

const Controllers = () => {
    const { user } = Context();
    const {
        list,
        loading,
        modal,
        total,
        actionLoading,
        exportLoading,
        packageList,
        getPacakgeList,
        actionForm,
        getSimList,
        simList,
        handleExport,
        setModal,
        getList,
        handleActions,
        partnerList,
        getPartnerList,
    } = ViewModel();

    const statusList = [
        { label: 'Tất cả', value: '' },
        { label: 'Chờ thanh toán', value: 10 },
        { label: 'Chờ giao hàng', value: 11 },
        { label: 'Chờ nhận hàng', value: 12 },
        { label: 'Giao hàng thành công', value: 13 },
        { label: 'Đã hủy', value: 14 },
        { label: 'Đã hòa mạng', value: 15 },
        { label: 'Hòa mạng thất bại', value: 16 },
    ]
    const [filterForm] = Form.useForm();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [paramsExport, setParamsExport] = useState({
        page: 0,
        limit: 10,
        start: moment().add(-15, 'days').format('DD-MM-YYYY'),
        end: moment().format('DD-MM-YYYY')
    });
    const [info, setInfo] = useState({})


    const handleDetails = async (info) => {
        actionForm.resetFields();
        setInfo(info);
        setModal(true);
    }

    const columnsTable = [
        {
            title: 'STT',
            key: 'stt',
            align: 'center',
            width: '5%',
            render: (item, record, index) => {
                return <span className='font-bold'>{index + 1}</span>
            }
        },
        {
            title: 'Thời gian tạo',
            key: 'createdAt',
            dataIndex: 'createdAt',
            align: 'center',
            width: '8%'
        },
        {
            title: 'Mã đơn hàng',
            key: 'orderCode',
            align: 'center',
            dataIndex: 'orderCode',
        },
        {
            title: 'Số Sim',
            key: 'number',
            align: 'center',
            dataIndex: 'number',
            width: '8%'
        },
        {
            title: 'Loại gói',
            key: 'package',
            align: 'center',
            dataIndex: 'package',
            width: '7%'
        },
        {
            title: 'Tên khách hàng',
            key: 'customerName',
            dataIndex: 'customerName',
            align: 'center',
        },
        {
            title: 'Số điện thoại khách hàng',
            key: 'customerPhone',
            dataIndex: 'customerPhone',
            align: 'center',
        },
        {
            title: 'Địa chỉ giao hàng',
            key: 'address',
            dataIndex: 'address',
            align: 'center',
        },
        {
            title: 'Email khách hàng',
            key: 'email',
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: 'Đối tác',
            key: 'partner',
            dataIndex: 'partner',
            align: 'center',
        },
        {
            title: 'Hành động',
            key: 'action',
            align: 'center',
            width: '8%',
            fixed: 'right',
            render: (text, record) => (
                <>
                    <Button type='primary' onClick={() => handleDetails(record)} icon={<FileSearchOutlined />}>Cập nhật</Button>
                </>
            )
        },
    ]

    const handleSearch = async (values, action) => {
        switch (action) {
            case 'search':
                let params = {
                    limit: pageSize,
                    page: 0,
                    package: values?.package === '' || values?.package === "Tất cả" ? undefined : values?.package,
                    partner: values?.number === '' || values?.partner === "Tất cả" ? undefined : values?.partner,
                    number: values?.number === '' ? undefined : values?.number,
                    customerPhone: values?.customerPhone === '' ? undefined : values?.customerPhone,
                    email: values?.email === '' ? undefined : values?.email,
                    start: moment(values?.date[0]?.$d).format('DD-MM-YYYY'),
                    end: moment(values?.date[1]?.$d).format('DD-MM-YYYY')
                };
                delete params?.date;
                setPage(0);
                setParamsExport(params);
                await getList(params);
                break;
        }
    };

    useEffect(() => {
        if (user?.isAdmin === true) {
            getPartnerList();
        }
    }, [user]);

    useEffect(() => {
        getPacakgeList();
        getList(paramsExport);
    }, [])

    const handleTableChange = async (pagination) => {
        setPage(pagination?.current - 1);
        setPageSize(pagination?.pageSize);
        let params = {
            ...paramsExport,
            page: pagination?.current - 1,
            limit: pagination?.pageSize
        };
        setParamsExport(params)
        await getList(params)
    };

    return {
        list,
        loading,
        modal,
        total,
        page,
        pageSize,
        actionForm,
        setInfo,
        actionLoading,
        getSimList,
        simList,
        info,
        user,
        getPartnerList,
        handleExport,
        partnerList,
        packageList,
        getList,
        handleActions,
        exportLoading,
        setModal,
        statusList,
        handleSearch,
        filterForm,
        columnsTable,
        handleTableChange,
    }
}

export default Controllers