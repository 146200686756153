import UserManagement from './pages/UserManagement';
import Transaction from './pages/Transaction';
import Setting from './pages/Setting';
import ChangePassword from './pages/ChangePassword';
import DataManagement from './pages/DataManagement';
import Dashboard from './pages/Dashboard';
import SimApplication from './pages/SimApplicationForPartner/Views/Views'

const route = [
  {
    path: '/',
    component: <Dashboard />,
  },
  {
    path: '/dashboard',
    component: <Dashboard />,
  },
  {
    path: '/transaction',
    component: <Transaction />,
  },{
    path: '/data-management',
    component: <DataManagement />,
  },
  {
    path: '/user-management',
    component: <UserManagement />,
  },
  {
    path: '/order-list',
    component: <SimApplication />,
  },
  {
    path: '/settings',
    component: <Setting />,
  },
  {
    path: '/change-password',
    component: <ChangePassword />,
  },
];

export default route;
