import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AccountBookTwoTone, FundTwoTone, ContactsTwoTone, SnippetsTwoTone, ShopTwoTone, BookTwoTone } from '@ant-design/icons';
import { Context } from '../context';
import SidebarLogo from '../assets/images/Sidebar_logo.png'

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { user } = Context();
  const { pathname } = location;
  const page = pathname.split('/')[1];
  const subPage = pathname.split('/')[2];
  const trigger = useRef(null);
  const sidebar = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, []);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="lg:w-64">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        aria-hidden="true"></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gradient-to-b from-gray-900 to-blue-600 p-4 transition-transform duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'
          }`}>
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-2 sm:px-1">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}>
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}

          <NavLink exact to="/dashboard" className="block">
            <div className="flex flex-col justify-center items-center object-contain">
              <img src={SidebarLogo} alt="sidebarLogo" />
              <div className="text-white italic">Mobitopup CMS</div>
            </div>

            {/* <div className="text-center m-auto w-8">
              <svg width="32" height="32" viewBox="0 0 32 32">
                <defs>
                  <linearGradient x1="28.538%" y1="20.229%" x2="100%" y2="108.156%" id="logo-a">
                    <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                    <stop stopColor="#A5B4FC" offset="100%" />
                  </linearGradient>
                  <linearGradient x1="88.638%" y1="29.267%" x2="22.42%" y2="100%" id="logo-b">
                    <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                    <stop stopColor="#38BDF8" offset="100%" />
                  </linearGradient>
                </defs>
                <rect fill="#6366F1" width="32" height="32" rx="16" />
                <path
                  d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                  fill="#4F46E5"
                />
                <path
                  d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                  fill="url(#logo-a)"
                />
                <path
                  d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                  fill="url(#logo-b)"
                />
              </svg>
            </div>
             */}
          </NavLink>
        </div>

        <div className="justify-start items-center">
          <ul className="mt-3">
            {(!!user.isAdmin ? ADMIN_PAGE : PAGE).map((item) =>
              // item.permissions.includes(user.role) &&
              item.sub ? (
                <li
                  key={item.page}
                  className={`px-3 py-2 rounded-lg ${page.startsWith(`${item.page}`) && 'bg-gray-900'
                    }`}>
                  <NavLink
                    exact
                    to={item.path}
                    className={`block text-gray-200 hover:text-white transition duration-150 ${page.startsWith(`${item.page}`) && 'hover:text-gray-200'
                      }`}>
                    <div className="flex items-center justify-between">
                      <div className="flex flex-grow">
                        {item.icon}
                        <span className="text-sm font-medium pl-3 mt-2">
                          {item.title}
                        </span>
                      </div>
                      <div className="flex flex-shrink-0 ml-2">
                        <svg
                          className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${page.startsWith(`${item.page}`) &&
                            'transform rotate-180'
                            }`}
                          viewBox="0 0 12 12">
                          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                        </svg>
                      </div>
                    </div>
                  </NavLink>
                  <ul
                    className={`pl-9 mt-1 ${!page.startsWith(`${item.page}`) && 'hidden'
                      }`}>
                    {item.sub.map(child => (
                      <li key={child.page} className="my-2 last:mb-0">
                        <NavLink
                          exact
                          to={child.path}
                          className={`block text-gray-200 hover:text-white transition duration-150 ${`${page}-${subPage}` === child.page &&
                            'text-indigo-400 hover:text-indigo-400'
                            }`}>
                          <span className="text-sm font-medium pl-3 mt-2">
                            {child.title}
                          </span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              ) : (
                <li
                  key={item.page}
                  className={`px-3 py-2 rounded-lg ${page === item.page &&
                    'bg-gray-100 text-black italic px-3 py-2'
                    }`}>
                  <NavLink
                    exact
                    to={item.path}
                    className={`text-gray-200 ${page === item.page && 'text-black'
                      }`}>
                    <div
                      className={`flex justify-start items-center align-middle`}>
                      <div className={`text-xl`}>
                        {item.icon}
                      </div>
                      <span
                        className={`text-sm font-medium pl-3 ${page === item?.page && 'text-black'
                          }`}>
                        {item.title}
                      </span>
                    </div>
                  </NavLink>
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

const ADMIN_PAGE = [
  {
    title: 'Tổng quan',
    path: '/dashboard',
    page: 'dashboard',
    icon: <FundTwoTone className='text-xl' twoToneColor="#eb2f96" />,
  },
  {
    title: 'Quản lý giao dịch',
    path: '/transaction',
    page: 'transaction',
    icon: <AccountBookTwoTone className="text-xl" twoToneColor="#eb2f96" />,
  },
  {
    title: 'Quản lý gói data',
    path: '/data-management',
    page: 'data-management',
    icon: <SnippetsTwoTone className="text-xl" twoToneColor="#eb2f96" />,
  },
  {
    title: 'Quản lý User',
    path: '/user-management',
    page: 'user-management',
    icon: <ContactsTwoTone className="text-xl" twoToneColor="#eb2f96" />,
  },
  {
    title: 'Quản lý đơn hàng',
    path: '/order-list',
    page: 'order-list',
    icon: <ShopTwoTone className={`text-xl`} twoToneColor="#eb2f96" />,
  },
];

const PAGE = [
  {
    title: 'Tổng quan',
    path: '/dashboard',
    page: 'dashboard',
    icon: <FundTwoTone className="text-xl" twoToneColor="#eb2f96" />,
  },
  {
    title: 'Quản lý giao dịch',
    path: '/transaction',
    page: 'transaction',
    icon: <AccountBookTwoTone className="text-xl" twoToneColor="#eb2f96" />,
  },
  {
    title: 'Quản lý đơn hàng',
    path: '/order-list',
    page: 'order-list',
    icon: <ShopTwoTone className="text-xl" twoToneColor="#eb2f96" />,
  }
];

export default Sidebar;
