import React from 'react';
import { Row, Col, Skeleton } from 'antd'
import moment from 'moment';

const Revenue = (props) => {
  const {data, loading} = props;
  return (
    <Skeleton active loading={loading}>
      <Row gutter={[8, 8]} className='p-2'>
        <Col xs={24} md={12}>
          <div className='flex flex-col gap-3 p-4 bg-white rounded-lg text-black shadow-lg'>
            <h1 className='text-base md:text-lg italic'>Doanh thu hôm nay ( {moment().format('DD-MM-YYYY')} )</h1>
            <span className={`font-bold text-lg ${data?.day !== 0 ? 'text-green-600' : 'text-red-700'}`}>{new Intl.NumberFormat().format(data?.day)} VND</span>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className='flex flex-col gap-3 p-4 bg-white rounded-lg text-black shadow-lg'>
            <h1 className='text-base md:text-lg italic'>Doanh thu tuần này ( Tuần {moment().format('WW')}-{moment().format('YYYY')} )</h1>
            <span className={`font-bold text-lg ${data?.week !== 0 ? 'text-green-600' : 'text-red-700'}`}>{new Intl.NumberFormat().format(data?.week)} VND</span>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className='flex flex-col gap-3 p-4 bg-white rounded-lg text-black shadow-lg'>
            <h1 className='text-base md:text-lg italic'>Doanh thu tháng này ( {moment().format('MM-YYYY')} )</h1>
            <span className={`font-bold text-lg ${data?.month !== 0 ? 'text-green-600' : 'text-red-700'}`}>{new Intl.NumberFormat().format(data?.month)} VND</span>
          </div>
        </Col>
        <Col xs={24} md={12} >
          <div className='flex flex-col gap-3 p-4 bg-white rounded-lg text-black shadow-lg'>
            <h1 className='text-base md:text-lg italic'>Doanh thu tháng trước ( {moment().subtract(1, 'month').format('MM-YYYY')} )</h1>
            <span className={`font-bold text-lg ${data?.lastMonth !== 0 ? 'text-green-600' : 'text-red-700'}`}>{new Intl.NumberFormat().format(data?.lastMonth)} VND</span>
          </div>
        </Col>
      </Row>
    </Skeleton>

  )
}

export default Revenue