import {
  AuditOutlined,
  CalendarOutlined,
  CodeOutlined,
  FileExcelOutlined,
  FileSearchOutlined,
  KeyOutlined,
  LoadingOutlined,
  PhoneOutlined,
  UserOutlined,
  WalletOutlined
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  message
} from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ModalTransaction from '../../components/Modal/Transaction';
import { Context } from '../../context';
import api from '../../utils/axios';
import { formatCurrency, getStatus } from '../../utils/helper';
import popup from '../../utils/popup';

const defaultDate = [dayjs(moment().subtract(15, 'days')), dayjs(moment())];
const DATE_FORMAT = 'YYYY-MM-DD 00:00:00';

function Transaction() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [transId, setTransId] = useState('');
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [paramsExport, setParamsExport] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState('');
  const [transRef, setTransRef] = useState('');
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [partnerList, setPartnerList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [method, setMethod] = useState('Tất cả');
  const { user, getPartnerInfo } = Context();
  const [partner, setPartner] = useState('Tất cả');
  const [selectedDate, setSelectedDate] = useState({
    from: moment().subtract(15, 'days').format(DATE_FORMAT),
    to: moment().add(1, 'day').format(DATE_FORMAT),
  });

  const columnsTable = [
    {
      title: 'STT',
      width: '5%',
      render: (item, record, index) => (
        <span style={{ fontWeight: 'bold' }}>{index + 1}</span>
      ),
      align: 'center',
    },
    {
      title: 'ID giao dịch',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: 'Mã tham chiếu',
      dataIndex: 'transRef',
      key: 'transRef',
      align: 'center',
      render: text => (isEmpty(text) ? 'Không mã tham chiếu' : text),
    },
    {
      title: 'Tên gói',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (value, record) => (
        <span className="text-center">{record.mobileData.name}</span>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
    },
    {
      title: 'Đối tác',
      dataIndex: 'partner',
      key: 'partner',
      align: 'center',
      render: (text) => {
        if (isEmpty(text)) {
          return <span>Không đối tác</span>
        }
        else if (!isEmpty(text) && user?.isAdmin === false) {
          getPartnerInfo(text);
          return <span>{text[0]?.toUpperCase() + text?.slice(1)}</span>
        }
        else if (!isEmpty(text) && user?.isAdmin === true) {
          return <span>{text[0]?.toUpperCase() + text?.slice(1)}</span>
        }
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: value => (
        <span className="capitalize text-center">{getStatus(value)}</span>
      ),
    },
    {
      title: 'Phương thức',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'center',
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: value => (
        <span className="text-center">
          {moment(value).format('DD/MM/YYYY HH:mm')}
        </span>
      ),
    },
    {
      title: 'Tổng số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'center',
      render: value => (
        <span className="text-center font-bold text-green-700">{formatCurrency(value)}</span>
      ),
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'center',
      width: '6%',
      fixed: 'right',
      render: (text, record) => (
        <div className="flex justify-center items-center gap-x-3">
          {/* {record?.type === 1 && role?.isAdmin === true ? (
            <>
              <Tooltip title="Retry giao dịch shopback">
                <Button
                  type="primary"
                  className='flex justify-center items-center'
                  shape="circle"
                  icon={<RedoOutlined />}
                  onClick={() => handleRetryShopback(record.id)}
                />
              </Tooltip>
              <Divider type="vertical" />
            </>
          ) ? record?.type === 0 && role?.isAdmin === true  (<Tooltip title="Retry giao dịch">
            <Button
              type="primary"
              className='flex justify-center items-center'
              shape="circle"
              icon={<RedoOutlined />}
              onClick={() => handleRetry(record)}
            />
          </Tooltip>)} */}
          <Tooltip title="Chi tiết">
            <Button
              shape="circle"
              className="flex justify-center items-center"
              icon={<FileSearchOutlined className="text-blue-700" />}
              onClick={() => handleViewDetails(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleOnChange = e => {
    setError({});
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const getLottery = async args => {
    try {
      setLoading(true);
      const params = {
        query: JSON.stringify({
          createdAt: { '>=': selectedDate?.from, '<': selectedDate?.to },
        }),
        page: page - 1,
        transRef: transRef === "" || transRef === "Không mã tham chiếu" ? undefined : transRef,
        paymentMethod: method === '' || method === 'Tất cả' ? undefined : method,
        limit: pageSize,
        view: true,
      };
      let response = await api.get('/api/v1/admin/transactions', {
        params: args || params,
      });

      setData(response.data);
      setTotal(response.pagination.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      popup.error(err && err?.message);
    }
  };

  const getPaymentMethod = async () => {
    try {
      await api.get('/api/v1/payment/methods').then((res) => {
        let data = [
          {
            code: 'Tất cả',
            name: 'Tất cả'
          },
          ...res?.data
        ]
        setPaymentMethod(data);
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getPartnerList = async () => {
    try {
      const res = await api.get('/api/v1/partner/list', {
        params: { limit: 1000 },
      });
      if (res) {
        let data = [
          {
            id: 'Tất cả',
            name: 'Tất cả',
          },
          {
            id: null,
            name: 'Không thuộc đối tác',
          },
          ...res?.data,
        ];
        setPartnerList(data);
      }
    } catch (error) {
      popup.error('Lỗi danh sách đối tác', 4);
      console.log(error);
    }
  };

  const handleViewDetails = async payload => {
    const { data } = await api.get(`/api/v1/admin/transactions/${payload.id}`);
    setInfo(data);
    setShowModal(true);
  };

  const handleOnChangeValue = (name, value) => {
    setInfo({ ...info, [name]: value });
  };

  const getTransaction = async (page, limit, transRef, method, args) => {
    for (const [key, value] of Object.entries(args)) {
      if (typeof value === 'string' && !value) {
        delete args[key];
      }
    }
    await getLottery({ page, limit, transRef, paymentMethod: method, query: JSON.stringify(args) });
  };

  const handleSearch = async () => {
    setPage(1);
    await getTransaction(0, pageSize, transRef === "" || transRef === "Không mã tham chiếu" ? undefined : transRef, method === '' || method === 'Tất cả' ? undefined : method, {
      ...paramsExport,
      createdAt: { '>=': selectedDate?.from, '<': selectedDate?.to },
    });
  };

  const handleFilter = value => {
    setFilter(value);
    setParamsExport({
      ...paramsExport,
      status: value === '' || value === 'Tất cả' ? undefined : value,
    });
  };

  const handleCode = ({ target }) => {
    setCode(target.value);
    setParamsExport({
      ...paramsExport,
      code: target.value === '' ? undefined : target.value,
    });
  };

  const handleTransRef = ({ target }) => {
    setTransRef(target.value);
  };

  const handleMethod = (value) => {
    setMethod(value);
  }

  const handlePhoneNumber = ({ target }) => {
    setPhoneNumber(target.value);
    setParamsExport({
      ...paramsExport,
      phoneNumber: target.value === '' ? undefined : target.value,
    });
  };

  const handleTransId = ({ target }) => {
    setTransId(target?.value);
    setParamsExport({
      ...paramsExport,
      id: target?.value === '' ? undefined : target?.value
    });
  }

  const handlePartners = value => {
    setPartner(value);
    setParamsExport({
      ...paramsExport,
      partner: value === '' || value === 'Tất cả' ? undefined : value,
    });
  };

  const handleDate = value => {
    const fromDate = value[0].format(DATE_FORMAT);
    const toDate = value[1].add(1, 'days').format(DATE_FORMAT);
    setSelectedDate({
      from: fromDate,
      to: toDate,
    });
    setParamsExport({
      ...paramsExport,
      createdAt: { '>=': fromDate, '<': toDate },
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setReadOnly(false);
    setEdit(false);
    setInfo({});
    setError({});
  };

  const handleTableChange = async pagination => {
    setPageSize(pagination?.pageSize);
    setPage(pagination?.current);
    await getTransaction(pagination?.current - 1, pagination?.pageSize, transRef === "" || transRef === "Không mã tham chiếu" ? undefined : transRef, method === '' || method === 'Tất cả' ? undefined : method, {
      ...paramsExport,
      createdAt: { '>=': selectedDate?.from, '<': selectedDate?.to },
    });
  };

  const handleExport = async () => {
    try {
      setExportLoading(true);
      let params = {
        status: filter === '' ? undefined : filter,
        phoneNumber: phoneNumber === '' ? undefined : phoneNumber,
        code: code === '' ? undefined : code,
        transRef:
          transRef === '' || transRef === 'Không mã tham chiếu'
            ? undefined
            : transRef,
        partner: partner === "" || partner === "Tất cả" ? undefined : partner,
        paymentMethod: method === '' || method === 'Tất cả' ? undefined : method,
        startDate: selectedDate?.from,
        endDate: selectedDate?.to,
      };
      const res = await api.get(
        '/api/v1/mobile/data/transactions/exportTransaction',
        {
          headers: {
            'Content-Type': 'multipart/form-data',

          },
          responseType: 'arraybuffer',
          params: { ...params },
        },
      );
      if (res) {
        const file = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = `Lich_su_giao_dich_${moment(selectedDate?.from, "YYYY-MM-DD").format("DD-MM-YYYY")}_${moment(selectedDate?.to, "YYYY-MM-DD").subtract(1, 'day').format("DD-MM-YYYY")}.xlsx`;
        link.click();
        setExportLoading(false);
      }
    } catch (error) {
      console.log(error);
      setExportLoading(false);
      message.error({
        content: 'Có lỗi hệ thống xuất Báo cáo !',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    if(user?.isAdmin === true) {
      getPartnerList();
    }
  }, [user]);

  useEffect(() => {
    getLottery();
    getPaymentMethod();
  }, []);

  return (
    <div style={{
      flex: 1,
      height: '100vh',
      marginBottom: 100,
    }} className='px-3 py-3'>
      <Card
        title="Quản lý giao dịch"
        className="shadow-2xl"
        extra={[
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#10661d',
              },
            }}
          >
            <Button
              type="primary"
              className="rounded-lg hover:shadow-xl font-semibold"
              icon={
                exportLoading === true ? (
                  <LoadingOutlined />
                ) : (
                  <FileExcelOutlined />
                )
              }
              disabled={exportLoading === true && true}
              onClick={handleExport}>
              {exportLoading === true ? 'Đang xuất Báo cáo...' : 'Xuất Excel'}
            </Button>
          </ConfigProvider>
        ]}>
        <Row gutter={[8, 8]} className="flex justify-center items-center">
          <Col lg={user?.isAdmin === true ? 6 : 12} span={user?.isAdmin === true ? 12 : 12}>
            <div>Trạng thái</div>
            <Select
              id='status'
              className="w-full"
              value={filter}
              style={{ width: '100%' }}
              suffixIcon={<AuditOutlined className="pl-1" />}
              onChange={handleFilter}>
              <Select.Option value="">Tất cả</Select.Option>
              <Select.Option value={0}>Khởi tạo</Select.Option>
              <Select.Option value={1}>Đang xử lý</Select.Option>
              <Select.Option value={2}>Hoàn thành</Select.Option>
              <Select.Option value={3}>Lỗi</Select.Option>
              <Select.Option value={4}>Đã xóa</Select.Option>
              <Select.Option value={5}>Chờ xác nhận</Select.Option>
            </Select>
          </Col>
          {user?.isAdmin === true && (
            <>
              <Col span={12} lg={6}>
                <div>Đối tác</div>
                <Select
                  className="w-full"
                  value={partner}
                  suffixIcon={<UserOutlined className="pl-1" />}
                  onChange={handlePartners}>
                  {partnerList?.map(item => (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name[0]?.toUpperCase() + item?.name?.slice(1)}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={12} lg={6}>
                <div>Phương thức</div>
                <Select
                  className="w-full"
                  value={method}
                  suffixIcon={<WalletOutlined className="pl-1" />}
                  onChange={handleMethod}>
                  {paymentMethod?.map(item => (
                    <Select.Option key={item?.code} value={item?.code}>
                      {item?.name[0]?.toUpperCase() + item?.name?.slice(1)}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col lg={6} span={12}>
                <div>ID giao dịch</div>
                <Input
                  placeholder="Nhập mã id"
                  onChange={handleTransId}
                  onPressEnter={async ({ target }) => {
                    handleTransId({ target });
                    await handleSearch();
                  }}
                  value={transId.trim()}
                  prefix={<KeyOutlined className="pr-1" />}
                />
              </Col>
            </>
          )}

          <Col lg={user?.isAdmin === true ? 6 : 12} span={12}>
            <div>Code</div>
            <Input
              id='code'
              placeholder="Nhập mã giao dịch"
              onChange={handleCode}
              onPressEnter={async ({ target }) => {
                handleCode({ target });
                await handleSearch();
              }}
              value={code.trim()}
              prefix={<KeyOutlined className="pr-1" />}
            />
          </Col>
          <Col lg={user?.isAdmin === true ? 6 : 12} span={12}>
            <div>Mã tham chiếu</div>
            <Input
              id='trans-ref'
              placeholder="Nhập mã tham chiếu"
              onChange={handleTransRef}
              onPressEnter={async ({ target }) => {
                handleTransRef({ target });
                await handleSearch();
              }}
              value={transRef.trim()}
              prefix={<CodeOutlined className="pr-1" />}
            />
          </Col>
          <Col lg={user?.isAdmin === true ? 6 : 12} span={12}>
            <div>Số điện thoại</div>
            <Input
              id='phone'
              placeholder="Nhập số điện thoại"
              onChange={handlePhoneNumber}
              onPressEnter={async ({ target }) => {
                handlePhoneNumber({ target });
                await handleSearch();
              }}
              value={phoneNumber.trim()}
              prefix={<PhoneOutlined className="pr-1" />}
            />
          </Col>
          <Col lg={user?.isAdmin === true ? 6 : 24} span={12}>
            <div>Ngày</div>
            <DatePicker.RangePicker
              format={'DD-MM-YYYY'}
              id='date-picker'
              defaultValue={defaultDate}
              onChange={handleDate}
              className="w-full"
              allowClear={false}
              suffixIcon={<CalendarOutlined className="pl-1" />}
              disabledDate={d => !d || d.isAfter(Date.now())}
            />
          </Col>
          <Col span={24} className="mt-1">
            <Button
              type="primary"
              id='search'
              style={{ width: '100%', fontWeight: 600 }}
              onClick={handleSearch}
              icon={loading === true ? <LoadingOutlined /> : null}>
              {loading === true ? '' : 'Tra cứu'}
            </Button>
          </Col>
          <Col span={24} className="mt-1">
            <Spin
              spinning={loading}
              indicator={
                <LoadingOutlined className="text-blue-600 font-semibold" />
              }
              tip={<span className="font-semibold">Loading</span>}>
              <Table
                columns={user?.isAdmin === false ? columnsTable?.filter((item) => {
                  return item?.title !== 'ID giao dịch' && item?.title !== 'Phương thức'
                }) : columnsTable}
                rowKey={record => record.id}
                dataSource={data}
                onChange={handleTableChange}
                scroll={{ x: 2000, y: 1000 }}
                className="h-full"
                pagination={{
                  pageSize,
                  current: page,
                  total,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100'],
                }}
                summary={() => (
                  <Table.Summary fixed={'bottom'}>
                    <Table.Summary.Row className="w-full">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={2}
                        className="text-sm">
                        Tổng giao dịch:{' '}
                        <span className="font-bold ml-2 text-blue-700">
                          {total}
                        </span>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </Spin>
          </Col>
        </Row>
      </Card>
      <ModalTransaction
        show={showModal}
        admin={user?.isAdmin}
        title="Thông tin chi tiết"
        close={handleClose}
        handleOnChange={handleOnChange}
        handleOnChangeValue={handleOnChangeValue}
        readOnly={readOnly}
        edit={edit}
        info={info}
        error={error}
      />
    </div>
  );
}

export default Transaction;
